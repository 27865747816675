function getRequiredEnv(value: string | undefined, name: string) {
  if (!value) {
    throw new Error(`Insufficient env var: ${name}`)
  }
  return value
}

/**
 * 서버 코드에서 사용하는 환경 변수
 */

export const API_URI_BASE = process.env.API_URI_BASE

/**
 * 클라이언트 코드에서 사용하는 환경 변수
 */

export const WEB_URL_BASE = getRequiredEnv(
  process.env.NEXT_PUBLIC_WEB_URL_BASE,
  'NEXT_PUBLIC_WEB_URL_BASE',
)

export const APP_URL_SCHEME = getRequiredEnv(
  process.env.NEXT_PUBLIC_APP_URL_SCHEME,
  'NEXT_PUBLIC_APP_URL_SCHEME',
)

export const AF_ONELINK_PID = getRequiredEnv(
  process.env.NEXT_PUBLIC_AF_ONELINK_PID,
  'NEXT_PUBLIC_AF_ONELINK_PID',
)

export const AF_ONELINK_ID = getRequiredEnv(
  process.env.NEXT_PUBLIC_AF_ONELINK_ID,
  'NEXT_PUBLIC_AF_ONELINK_ID',
)

export const AF_ONELINK_SUBDOMAIN = getRequiredEnv(
  process.env.NEXT_PUBLIC_AF_ONELINK_SUBDOMAIN,
  'NEXT_PUBLIC_AF_ONELINK_SUBDOMAIN',
)

export const GA_PROPERTY_ID = getRequiredEnv(
  process.env.NEXT_PUBLIC_GA_PROPERTY_ID,
  'NEXT_PUBLIC_GA_PROPERTY_ID',
)

export const FB_APP_ID = getRequiredEnv(
  process.env.NEXT_PUBLIC_FB_APP_ID,
  'NEXT_PUBLIC_FB_APP_ID',
)

export const DEFAULT_PAGE_TITLE =
  process.env.NEXT_PUBLIC_DEFAULT_PAGE_TITLE || '나를 아는 여행 앱, 트리플'
export const DEFAULT_PAGE_DESCRIPTION =
  process.env.NEXT_PUBLIC_DEFAULT_PAGE_DESCRIPTION ||
  '예약부터 일정까지 나에게 꼭 맞춰주니까 여행이 더 쉬워집니다. 트리플로 동행과 함께 일정을 짜고 최적의 동선을 안내받으세요. 전 세계 최신 여행 정보와 리얼한 리뷰를 마음껏 활용하세요.'
