import { useState, useEffect } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import * as Sentry from '@sentry/browser'
import {
  EnvProvider,
  generateUserAgentValues,
  HistoryProvider,
  UserAgentProvider,
  SessionContextProvider,
} from '@titicaca/react-contexts'
import {
  AppName,
  App as AppType,
  parseTripleClientUserAgent,
  TripleClientMetadataProvider,
} from '@titicaca/react-triple-client-interfaces'
import {
  CommonMeta,
  EssentialContentMeta,
  FacebookOpenGraphMeta,
  FacebookAppLinkMeta,
  AppleSmartBannerMeta,
  ThemeColorMeta,
} from '@titicaca/meta-tags'
import { get } from '@titicaca/fetcher'
import { useTripleFallbackActionRemover } from '@titicaca/triple-fallback-action'
import Cookies from 'universal-cookie'
import { i18nConfig } from '@titicaca/i18n'
import { appWithTranslation } from '@titicaca/next-i18next'

import { initializeFirebaseAnalytics } from '@/common/firebase'
import {
  AF_ONELINK_ID,
  AF_ONELINK_PID,
  AF_ONELINK_SUBDOMAIN,
  APP_URL_SCHEME,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_TITLE,
  FB_APP_ID,
  GA_PROPERTY_ID,
  WEB_URL_BASE,
} from '@/common/env'

const GA_INIT_SCRIPT = `
window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
ga('create', '${GA_PROPERTY_ID || ''}', 'auto');
`

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  })
}

initializeFirebaseAnalytics()

const initialSessionContextProviderProps: Parameters<
  typeof SessionContextProvider
>[0] = {
  children: undefined,
  type: 'app',
  props: {
    initialSessionId: undefined,
    initialUser: undefined,
  },
}

interface CustomAppProps {
  pageProps: { [key: string]: unknown } | undefined
}

function MyApp({ Component, pageProps }: AppProps & CustomAppProps) {
  if (!APP_URL_SCHEME || !WEB_URL_BASE) {
    throw new Error(
      'Insufficient Environment Variables\n- NEXT_PUBLIC_APP_URL_SCHEME\n- NEXT_PUBLIC_WEB_URL_BASE',
    )
  }
  const [sessionContextProviderProps, setSessionContextProviderProps] =
    useState<Parameters<typeof SessionContextProvider>[0]>(
      initialSessionContextProviderProps,
    )
  const [userAgent, setUserAgent] =
    useState<ReturnType<typeof generateUserAgentValues>>()
  const [
    tripleClientMetadataProviderProps,
    setTripleClientMetadataProviderProps,
  ] = useState<AppType | null>(null)

  useEffect(() => {
    const navigatorUserAgent = navigator.userAgent

    setUserAgent(generateUserAgentValues(navigatorUserAgent))
    setTripleClientMetadataProviderProps(
      parseTripleClientUserAgent(navigatorUserAgent),
    )
  }, [])

  useEffect(() => {
    const sessionId = new Cookies().get('x-soto-session') as string

    async function setContextProviderProps() {
      const { ok, parsedBody } = await get(`/api/users/me`)
      const user = parsedBody as Parameters<
        typeof SessionContextProvider
      >[0]['props']['initialUser']

      const result: Parameters<typeof SessionContextProvider>[0] = ok
        ? tripleClientMetadataProviderProps
          ? {
              type: 'app',
              props: {
                initialSessionId: sessionId,
                initialUser: user,
              },
            }
          : {
              type: 'browser',
              props: {
                initialSessionAvailability: true,
                initialUser: user,
              },
            }
        : {
            type: 'browser',
            props: {
              initialSessionAvailability: false,
              initialUser: undefined,
            },
          }

      setSessionContextProviderProps(result)
    }
    void setContextProviderProps()
  }, [tripleClientMetadataProviderProps])

  useTripleFallbackActionRemover()

  return (
    <>
      <Head>
        {!tripleClientMetadataProviderProps ? (
          <>
            <script dangerouslySetInnerHTML={{ __html: GA_INIT_SCRIPT }} />
            <script async src="https://www.google-analytics.com/analytics.js" />
          </>
        ) : null}
      </Head>

      <CommonMeta />
      <ThemeColorMeta content="#ffffff" />

      <EnvProvider
        appUrlScheme={APP_URL_SCHEME}
        webUrlBase={WEB_URL_BASE}
        authBasePath="/"
        facebookAppId={FB_APP_ID}
        defaultPageTitle={DEFAULT_PAGE_TITLE}
        defaultPageDescription={DEFAULT_PAGE_DESCRIPTION}
        afOnelinkId={AF_ONELINK_ID}
        afOnelinkPid={AF_ONELINK_PID}
        afOnelinkSubdomain={AF_ONELINK_SUBDOMAIN}
      >
        <EssentialContentMeta />

        <FacebookOpenGraphMeta
          canonicalUrl={`${process.env.NEXT_PUBLIC_WEB_URL_BASE || ''}`}
          type="website"
          locale="ko_KR"
        />

        <FacebookAppLinkMeta
          appName="트리플"
          appId="1225499481"
          appPath="/"
          appPackageName="com.titicacacorp.triple"
        />

        <AppleSmartBannerMeta appId="1225499481" appPath="/" />

        <SessionContextProvider {...sessionContextProviderProps}>
          <TripleClientMetadataProvider {...tripleClientMetadataProviderProps}>
            <UserAgentProvider
              value={userAgent as ReturnType<typeof generateUserAgentValues>}
            >
              <HistoryProvider
                isPublic={!tripleClientMetadataProviderProps}
                isAndroid={
                  tripleClientMetadataProviderProps?.appName === AppName.Android
                }
              >
                <Component {...pageProps} />
              </HistoryProvider>
            </UserAgentProvider>
          </TripleClientMetadataProvider>
        </SessionContextProvider>
      </EnvProvider>
    </>
  )
}

export default appWithTranslation(MyApp, i18nConfig)
